@font-face {
  font-family: 'Poppins';
  src: url('styles/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('styles/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Noto';
  src: url('styles/fonts/NotoSans.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  background-color: #FAFAFA !important;
  font-family: poppins;
}


.text-ui {

  color: #100A0A;
}

.text-ui-second {
  color: #473B1F;
}
.text-gray {
  color: #E6E6E5 !important;
}

.text-violet {
  color: #7b2bb1 !important;
}
.text-ocean {
  color: #2d2bb1 !important;
}
.text-orange {
  color: #bd7d1e !important;
}
.text-operout {
  color: #bd431e !important;
}
.text-green {
  color: #43b12b !important;
}

.text-olive {
  color: #43b12b !important;
}

.text-yellow {
  color: #FFEA00 !important;
}

.text-yellow-mast {
  color: #FFdA00 !important;
}


.border-ui {
  border-color: #fafafa !important;
}

.border-multi {
border: 2px solid#c4b69f !important;
}


.border-ui-second {
  border: 2px solid#71624B !important;
}

.bg-gray,
.accordion-button:focus,
.accordion-button:active,
.accordion-button:not(.collapsed) {
  background-color: #E6E6E5 !important;

}

.accordion-button:not(.collapsed) {
  background-color: #e9e9e9 !important;

}

.bg-ui-second {
  background-color: #71624B;

}

.bg-ui {
  background-color: #fafafa;
}

.bg-error{
  background-color: rgb(212, 134, 134);
}

.bg-succes {
  background-color: #43B02A !important;
}

.bg-attention {
  background-color: #cf6464 !important;
}

.bg-darker {
  background-color: #666 !important;
}

.bg-darkGray {
  background-color: #555 !important;

}

.bg-green {
  background-color: #43b12b !important;
}
.bg-yellow {
  background-color: #FFEA00 !important;
}

.bg-yellow-mast {
  background-color: #FFdA00 !important;
}

.border-gray {
  border: 2px solid#E6E6E5 !important;
}

.border-darkGray {
  border: 2px solid#A6A6A6 !important;
}

.border-green {
  border-color: #43b12b !important;
}

.border-yellow {
  border-color: #FFEA00 !important;
}

.logo-app {
  --animate-duration: 1s;
  margin: auto;
  width: 160px;
  height: 160px;
  background-image: url('../src/styles/logo-easybox.png');
  background-repeat: no-repeat;
  background-size: 160px;
}


.top-nav-design {
  height: 65px;
  background-color: #ffda00;
  width: 100% !important;
}

.bottom-navbar {
  height: 65px;
  background-color: #ddd;
  width: 100% !important;
  border-top: 1px solid #eee;
}


@media (max-height: 350px) {
  .bottom-navbar {
    display: none;
  }
}

.button-auth {
  background-color: #43B02A;
  border: 2px solid #43B02A;
}

.button-auth:active,
.button-auth:hover {
  background-color: #43B02A;
  border: 2px solid #43B02A;
}

.auth-cont-sup {
  background-color: #555 !important;
  height: 100vh;
}

.auth-cont {
  height: 100vh;
  overflow-y: hidden;
}

.auth-form {
  background-color: #555 !important;
  padding-top: 10%;
  overflow-y: hidden;
  margin-bottom: 10%;
}

.update-form {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.body-form {
  margin-top: 50px;
}

@media (min-width: 712px) AND (max-width: 912px) {
  .body-form {
    width: 75%;
  }
}

@media (min-width: 912px) AND (max-width: 1160px) {
  .body-form {
    width: 50%;
  }
}

@media (min-width: 1160px) {
  .body-form {

    width: 30%;
  }
}

.card-body {
  overflow-y: hidden !important;
}

@media (max-height: 515px) {
  .logo-app {
    height: 120px;
    background-size: 120px;
    background-position: 50% 0px;
  }

  .auth-cont-sup {
    background-color: #555 !important;
  }

  .teko {
    font-size: 1.5rem !important;
    margin-bottom: 2%;
  }

  .auth-form {
    padding-top: 0%;
    width: 100%;
    margin: auto;
    overflow-y: auto;
  }

  .menu-link {
    padding: 0;
  }
}

.menu-link {
  padding: 10px 0;
}

.menu-link:hover {
  background-color: rgb(255, 255, 255);
  color: #e6e4e4;
  border-start-start-radius: 2px;
  border-end-start-radius: 2px;
  border-left: 4px solid #43b12b;
  cursor: pointer;
}

.log-out {
  position: absolute;
  bottom: 10px;
  right: -15px;
}

.cde {
  position: relative;
  overflow: auto;
  min-height: 100vh;

}

@media (max-height: 374px) {
  .cde {
    height: 120vh;
    overflow-y: auto;
    padding-bottom: 150px;
  }
}


/*-------------------------
  Font
--------------------------*/

.ff-agency,
.header-title {
  line-height: 20px;
  font-size: 1rem;
  font-family: 'Noto', sans-serif;
}

.font-75 {
  font-size: 0.75rem;
}

.font-85 {
  font-size: 0.85rem;
}

.font-weight-300 {
  font-weight: 300;
}

.teko {
  font-size: 2.5rem;
  font-weight: 800;
  font-family: 'Changa', sans-serif;
}

/*-------------------------
    Menu
--------------------------*/

.bottom-navbar {
  position: fixed;
  bottom: 0;
  font-size: 0.7rem
}

.nav-item {
  width: 20%;
}


.list-item:active {
  background-color: #9b9b9b !important;

}

.list-item:focus-visible {
  outline: 2px solid #9b9b9b;
  border-radius: 3px;
}


.list-item .size-indicator:active {
  border-color: #9b9b9b !important;
}

.menu-right {
  width: 75% !important;
  z-index: 2520;
}

@media (min-width: 768px) {
  .ff-agency {
    letter-spacing: 0.07rem;
    font-size: 1.2rem;
  }

  .menu-right {
    width: 50% !important;
  }
}

@media (min-width: 915px) {
  .menu-right {
    width: 25% !important;
  }
}

.user-name {
  font-family: poppins, sans-serif;
  color: #180202;
}

.bounced-arrow {
  animation: bounce;
  animation-duration: 2s;
  animation-delay: 0.4s;
}

.img-blur {
  filter: blur(1px);
}

.bg-content {
  background-image: url('styles/no-order.webp');
  background-size: cover;
}

.item-locker-list,
.font-55 {
  font-size: 0.55rem;
}

.font-65 {
  font-size: 0.65rem;
}


.dash-placeholder {
  height: 100px;
  
}

.detail-table {
  display: flex;
  margin-bottom: 2px;
}

.detail-table .name {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 40%;
  flex: 1 0 40%;
  padding-left: 0.625rem;
  margin-right: 0.625rem;
  word-break: normal;
}

.custom-dropdown {
  width: 100%;
  padding: 0;
  font-size: 3.5rem;
}

.manual {
  opacity: 100%;
}

.item-menu {
  height: 30px;
}

.history {
  border-left: 1px dotted #343a40;
}

.puce {
  margin-left: -20.5px;
}


/*-------------------------
    Back To Top  
--------------------------*/
.back-to-top {
  position: fixed;
  width: 100%;
  bottom: 8%;
  z-index: 9;
}



/*-------------------------
| | History
--------------------------*/

.history-tl-container {
  font-family: "Roboto", sans-serif;
  margin: auto;
  position: relative;
}

@media (min-width: 1410px) {
  .history-tl-container {
    width: 30% !important;
  }

  .history-tl-container ul.tl {
    margin: 20px 15% !important;

  }
}


@media (min-width: 1095px) and (max-width: 1409px) {
  .history-tl-container {
    width: 100% !important;
  }

  .history-tl-container ul.tl {
    margin: 20px 20% !important;

  }
}

@media (min-width: 915px) {
  .history-tl-container {
    width: 100% !important;
    font-size: 28px;
  }

  .history-tl-container ul.tl li {

    margin-left: 320px !important;
  }
}

@media (min-width: 667px) {
  .history-tl-container {
    width: 100% !important;
  }

  .history-tl-container ul.tl li {
    margin-left: 45% !important;
  }
}

@media (min-width: 360px) and (max-width: 665px) {
  .history-tl-container {
    width: 100%;
    padding-right: 20px;
  }

  ul.tl li .timestamp-current {
    color: #999999;
    position: absolute;
    left: -53%;
    text-align: right;
    font-size: 12px;
  }
}

.history-tl-container ul.tl {
  margin: 20px 0;
  padding: 0;
  display: inline-block;
}

.history-tl-container ul.tl li {
  list-style: none;
  margin: auto;
  margin-left: 150px;
  min-height: 50px;
  border-left: 1px dashed #43B02A;
  margin-bottom: 5px;
  padding: 0 0 50px 30px;
  position: relative;
}

.history-tl-container ul.tl li:first-child {
  border-left: 0;
}

.history-tl-container ul.tl li::before {
  position: absolute;
  left: -10px;
  top: -5px;
  content: " ";
  border: 8px solid rgba(255, 255, 255, 0.74);
  border-radius: 500%;
  background: #43B02A;
  height: 20px;
  width: 20px;
  transition: all 500ms ease-in-out;
}

.history-tl-container ul.tl li:hover::before {
  border-color: #43B02A;
  transition: all 1000ms ease-in-out;
}

ul.tl li .item-title-current {
  color: #ffc400;
  font-weight: 600;
}

ul.tl li .item-title {
  color: #180202;
}

ul.tl li .item-detail-current {
  color: #43b12b;
  font-size: 12px;
}

ul.tl li .item-detail {
  color: #473B1F;
  font-size: 12px;
}

ul.tl li .timestamp {
  color: #473B1F;
  position: absolute;
  left: -53%;
  text-align: right;
  font-size: 12px;
  width: 25px;
}

ul.tl li .timestamp-current {
  color: #ffc400;
  position: absolute;
  left: -53%;
  text-align: right;
  font-size: 12px;
}

.delete-button {
  position: absolute;
  right: 4%;
  z-index: 100;
}

.delete-history-button {
  position: absolute;
  right: 3%;
  top: 4%;
  z-index: 100;
}

.input-button {
  position: absolute;
  right: 3%;
  top: 5%;
  z-index: 100;
}

.input-history-button {
  position: absolute;
  right: 3%;
  top: 10%;
  z-index: 100;
}

@media (max-width: 767px) {
  input::placeholder {
    font-size: 0.85rem;
  }
}

@media (min-width: 767px) {
  .delete-button {
    right: 1%;
    top: -5%;
  }

  .delete-history-button {
    right: 1%;
    top: -3%;
  }
}

@media (min-width: 970px) {
  .delete-history-button {
    right: 0.8%;
    top: -5%;
  }
}

.badge {
  margin-left: -5%;
}

.badge2 {
  margin-left: -65%;
}

.fusion {
  margin-left: -199%;
}

.badge-hoster {
  position: relative;
  width: auto;
}

.my-badge {
  position: absolute;
  left: 25px;
  top: -2px;
}

.size-indicator {
  position: absolute;
  margin-left: 38px;
  margin-top: -19px;
  background-color: #ffcc00;
  border: 2px solid;
  border-radius: 20px;
  padding: 0 5px 0 5px;
}

.size-indicator-right {
  position: absolute;
  margin-left: 27px;
  margin-top: -19px;
  background-color: #ffcc00;
  border: 2px solid;
  border-radius: 20px;
  padding: 0 5px 0 5px;
}

.size-indicator-small {
  position: absolute;
  margin-left: 21px;
  margin-top: -19px;
  background-color: #ffcc00;
  border: 2px solid;
  border-radius: 20px;
  padding: 0 5px 0 5px;
}



.temp-size .size-inducator:hover {
  border-color: "#555"
}


.install {
  display: none;
}

.tempzone {
  width: 100%;
}

.video-container {
  width: 100%;
  position: fixed;
  bottom: 10%;
  z-index: 450;
}

video {
  width: 100%;
}

.fab {
  position: fixed;
  bottom: 13%;
  right: 20px;
  z-index: 500;
  box-shadow: 2px 5px 5px rgb(133, 133, 133);
}

.fab2 {
  position: fixed;
  bottom: 23%;
  right: 25px;
  z-index: 501;
  }
.fab3 {
    position: fixed;
    bottom: 13%;
    left: 5px;
    z-index: 500;
    box-shadow: 2px 5px 5px rgb(133, 133, 133);
  }
.fab-annulation {
    position: fixed;
    bottom: 20%;
    left: 5px;
    z-index: 500;
    box-shadow: 2px 5px 5px rgb(133, 133, 133);
  }

.order-list {
  padding-bottom: 20%;
}

.pagination {
  justify-content: flex-end;
  z-index: 2;
}

.pagination button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.1rem 0.6rem;
  margin: 0 0.25rem;
  cursor: pointer;
  z-index: 500;
}

.pagination button.active {
  background-color: #ffcc00;
  color: white;
  border-color: #ffcc00;
}

#notfound-image {
  width: 70%;
  margin-bottom: 30%;
}

@media (min-width: 769px) {
  #notfound-image {
    width: 30%;
    margin-bottom: 5%;
  }
}

.go-back {
  position: fixed;
  top: 3.2%;
  left: 4%;
  z-index: 250;
}

@media (min-width: 339px) AND (max-width: 375px) {
  .go-back {
    top: 2.5%;
    left: 4%;
  }
}

@media (min-width: 376px) AND (max-width: 712px) {
  .go-back {
    top: 1.7%;
    left: 4%;
  }
}

@media (min-width: 712px) AND (max-width: 970px) {
  .go-back {
    top: 1.8%;
    left: 4%;
  }
}

@media (min-width: 970px) AND (max-width: 1920px) {
  .go-back {
    top: 1%;
    left: 4%;
  }
}

.full-row {
  height: 100%;
}


.cursor {
  cursor: pointer;
}


.qty-form {
  padding-top: 25%;
}

@media (max-width: 330px) {
  .qty-form {
    padding-top: 5%;
  }
}




.page {
  width: 50px !important;
}


.table-row {
  background-color: #fafafa;
}

.fixed-col {
  position: absolute;
  top: 26%;
  left: 42.5%;
}

@media (max-width: 413px) {

  .fixed-col {
    position: absolute;
    top: 21%;
    left: 33%;
  }
}

.stage{
  height:4px;
}